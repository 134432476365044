<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    @click="closeModal()"
  >
    <div
      class="flex items-center flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 pt-24 pb-12 space-y-4 md:w-3/5 text-center font-body relative"
      ref="discountModal"
    >  
    <i @click="$emit('closemodal')" class="absolute cursor-pointer hover:-rotate-90 transition ease-linear duration-200 top-4 right-6 fas fa-times text-2xl text-gray-400"></i>
    <img src="@/assets/img/img/confetti.png" alt="Confetti">
    <p class="w-4/5 md:w-3/5 mx-auto text-sm">You have been granted a {{ user?.hasDiscount?.percentage ?? "50" }}% discount on your payment. Quickly proceed to complete the payment before offer expires.</p>

    <button 
      @click="claimDiscount()" 
      class="rounded-md px-4 py-2 text-sm text-white bg-primary-green font-semibold uppercase"
    >Claim it</button>         
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
            courses: "auth/courses",
        }),
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        closeModal() {
          const box = this.$refs.discountModal;
          const btn = this.$refs.closeBtn
          if (!box?.contains(event.target)  && event.target != btn) {
              return this.$emit("closemodal");
          }
          return;
        },
        async claimDiscount(){
          const loader = this.$loading.show();
          try {
            // switch users active batch on the users table
            await axios.post("auth/batch/switch", {
              batch_id: this.user?.hasDiscount?.batch_id,
            });
            await this.attempt({}); // re-fetch user auth data 
            loader.hide(); // hide loader 
            return this.$router.push({name: 'ApplicationPayment', params: { slug: this.user?.batch?.learning_type == 'instructor' ? 'hands-on' : 'self_paced' } }) 
          } catch (error) {
            loader.hide();
            console.error(error);
          }
        }
    },
};
</script>

<style></style>
