import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import router from "./router";
import "./assets/css/main.css";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import routeGuards from "./helpers/routeGuard";
import themeWatcher from "./helpers/themeWatcher";
import "mosha-vue-toastify/dist/style.css";
import '../node_modules/flowbite-vue/dist/index.css'


import "./registerServiceWorker.js"

routeGuards();

// axios defaults
axios.defaults.baseURL =
  process.env.NODE_ENV == "development"
    ? "http://localhost:8000/api/"
    : "https://dev-api.terrahq.co/api/";
  

/**n
 * Auto Refreshing
 */
axios.defaults.headers["app-version"] = localStorage.version || 1;

axios.interceptors.response.use(
  function (response) {
    if (location.host == 'dev.terrahq.co') {
      /**
       * * WHEN ON THE DEV SERVER, USE RECURSIVE ALGORITHM TO CAST ALL NUMBERS TO (INT)
       * TODO: RESEARCH ON A WAY TO SORT THE MySQLnd (MySQL Native Driver) ISSUE ON THE DEVELOPMENT BACKEND SERVER
       * ! IMPORTANT: DO NOT ADJUST THIS BLOCK OF CODE TO RUN ON THE PRODUCTION SERVER. IT WILL SLOW DOWN THE APP BY A GREAT FACTOR
       */ 
      const res = response
      const obj = response.data
      function loopThroughJSON(obj) {
        for (let key in obj) {
          if (typeof obj[key] == 'object') {
            const res = obj[key]
            loopThroughJSON(res);
          } else {
            const parsed = Number(obj[key], 10);
            obj[key] = isNaN(parsed) ? obj[key] : parsed;
          }
        }
      }
      loopThroughJSON(obj)
      res.data = obj
      return res;
    } else {
      return response;
    }
  },
  function (error) {
    // Reload user's browser to fetch new update
    if (error.response.status == 426) {
      localStorage.setItem("version", error.response.data.payload);
      setTimeout(() => {
        window.location.reload(true); // reload from server
      }, 500);
      return;
    }
    return Promise.reject(error);
  }
);

/**
 * Set The axios bearer token -
 */
store.subscribe((mutation) => {
  switch (mutation.type) {
    case "auth/SET_TOKEN":
      if (mutation.payload) {
        axios.defaults.headers["Authorization"] = `Bearer ${mutation.payload}`;
        localStorage.setItem("token", mutation.payload);
      } else {
        axios.defaults.headers["Authorization"] = null;
        localStorage.removeItem("token");
      }
      break;
  }
});
export const app = createApp(App);
store.dispatch("auth/preloader", localStorage.getItem("token")).then(() => {
  app
    .use(store)
    .use(router)
    .use(VueLoading, {
      isFullPage: true,
      loader: "dots",
      backgroundColor: "#00000",
      color: "#52A350",
    })
    .use(VueAxios, axios)
    .provide("axios", app.config.globalProperties.axios)
    .mount("#app");
});

// theme detection
themeWatcher(localStorage.theme ? localStorage.theme : "light");
